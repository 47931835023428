import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { isString } from "lodash";
import { useTranslation } from "react-i18next";
import { getColors } from "utils/getColors";
import { ReactComponent as ArrowUpDown } from "utils/img/arrow-up-down.svg";
import { ReactComponent as ClearIcon } from "utils/img/close.svg";
import { hexToRGB } from "utils/mixColors";
import { Margin, marginProps, marginStyle } from "UI/utility/maring";
import { LinearProgress } from "components/LinearProgress";
import { StyledLabelInput } from "./TextInput";

type SelectProps = {
  className?: string;
  placeholder?: string;
  name?: string;
  value?: string;
  isLoading?: boolean;
  inputValue?: string;
  onChangeInputValue?: (val: string) => void;
  onChange?: (val: string) => void;
  onFocus?: (e: unknown) => void;
  label?: string;
  error?: boolean | string;
  helperText?: string | boolean;
  disabled?: boolean;
  size?: "small" | "default";
  options: { value: string; label: string }[];
  withClear?: boolean;
} & Margin;

export const Select = ({
  className,
  placeholder,
  name,
  value,
  isLoading,
  inputValue,
  onChangeInputValue,
  onChange,
  onFocus,
  label,
  error,
  helperText,
  disabled,
  size,
  options,
  withClear,
  ...otherProps
}: SelectProps) => {
  const { t } = useTranslation();
  const [focus, setFocus] = useState<boolean>(false);
  const [showOptionList, setShowOptionList] = useState<boolean>(false);
  const [openUpwards, setOpenUpwards] = useState(false);
  const selectOptionListRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  const { pageBackground, accentColor } = getColors();

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        selectOptionListRef.current &&
        !selectOptionListRef.current.contains(event.target as Node)
      ) {
        setShowOptionList(false);
      }
    }

    function handlePositioning() {
      if (selectOptionListRef.current && contentRef.current) {
        const contentRect = contentRef.current.getBoundingClientRect();
        const rect = selectOptionListRef.current.getBoundingClientRect();
        const spaceBelow = window.innerHeight - rect.bottom;
        if (spaceBelow <= contentRect.height) {
          setOpenUpwards(true);
        }
      }
    }

    handlePositioning();
    window.addEventListener("resize", handlePositioning);
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
      window.removeEventListener("resize", handlePositioning);
    };
  }, [selectOptionListRef]);

  const handleSelectOption = (selectValue: string) => {
    if (onChange) onChange(selectValue);
    setShowOptionList(false);
  };

  return (
    <StyledSelectWrapper
      className={className}
      disabled={disabled}
      ref={selectOptionListRef}
      {...marginProps(otherProps)}
    >
      <StyledFieldset
        disabled={disabled}
        error={!!error}
        bordercolor={accentColor}
        focus={focus}
        size={size}
      >
        <StyledLabelInput empty={!label} size={size}>
          {label || ""}
        </StyledLabelInput>
        <StyledInput
          placeholder={placeholder}
          name={name}
          value={
            focus && isString(inputValue)
              ? inputValue
              : `${
                  options.find((item) => item.value === value)?.label ||
                  value?.length ||
                  ""
                }`
          }
          onChange={(e) => {
            if (onChangeInputValue) onChangeInputValue(e.target.value);
          }}
          readOnly={!isString(inputValue)}
          small={size === "small"}
          onClick={() => !disabled && setShowOptionList(!showOptionList)}
          onMouseDown={() => {
            if (!disabled) {
              setFocus(true);
              if (onChangeInputValue) onChangeInputValue("");
            }
          }}
          onBlur={() => !disabled && setFocus(false)}
          onFocus={(e) => {
            if (onFocus) onFocus(e);
          }}
          withClear={withClear}
        />
      </StyledFieldset>
      {withClear && value && (
        <StyledClearIcon onClick={() => handleSelectOption("")} />
      )}
      <StyledArrowUpDown
        small={size === "small" ? 1 : 0}
        open={showOptionList}
        onClick={() => !disabled && setShowOptionList(!showOptionList)}
      />
      {helperText ? (
        <StyledHelperText error={!!error}>{helperText}</StyledHelperText>
      ) : null}
      {isLoading && isString(inputValue) ? (
        <StyledWrapperModal
          isHelperText={!!helperText}
          showOptionList={showOptionList}
          openUpwards={openUpwards}
          ref={contentRef}
        >
          <LinearProgress />
        </StyledWrapperModal>
      ) : (
        <StyledWrapperModal
          isHelperText={!!helperText}
          showOptionList={showOptionList}
          openUpwards={openUpwards}
          ref={contentRef}
        >
          {options.length !== 0
            ? options.map((option, index) => (
                <StyledOption
                  key={index}
                  background={pageBackground}
                  onClick={() => handleSelectOption(option.value)}
                  active={option.value === value}
                  size={size}
                >
                  {option.label}
                </StyledOption>
              ))
            : t("Нет данных для отображения")}
        </StyledWrapperModal>
      )}
    </StyledSelectWrapper>
  );
};

const StyledFieldset = styled.fieldset<{
  error?: boolean;
  bordercolor: string;
  focus?: boolean;
  size?: string;
}>`
  border: none;
  border: 1px solid ${(props) => (props.error ? "#D00025" : "#B9BFC8")};
  padding: 0;
  margin-top: -8px;
  padding-left: 5px;
  height: 63.5px;
  &:hover {
    border: 1px solid
      ${(props) => (props.error ? "#D00025" : hexToRGB(props.bordercolor, 0.4))};
    legend {
      color: ${(props) => hexToRGB(props.bordercolor, 0.4)};
    }
  }
  ${(props) =>
    props.focus
      ? `
          border: 1px solid
            ${props.error ? "#D00025" : props.bordercolor} !important;
          legend {
            color: ${props.bordercolor} !important;
          }
        `
      : ""};
  ${(props) => (props.size === "small" ? "height: 54px;" : "")};
  &:disabled {
    border: 1px solid #c8cfdc;
    legend {
      color: #c8cfdc;
    }
  }
`;

const StyledArrowUpDown = styled(ArrowUpDown)`
  position: absolute;
  path {
    fill: #393939 !important;
  }
  top: 27px;
  right: 20px;
  ${(props: { open: boolean; small?: number }) =>
    props.open ? `transform: rotate(180deg);` : ""};
  ${(props) => (props.small ? `top: 22px;` : "")};
  cursor: pointer;
`;

const StyledOption = styled.div`
  padding: 12px 32px;
  margin-right: -24px;
  margin-left: -24px;
  cursor: pointer;
  border-bottom: 1px solid
    ${(props: { background: string; active: boolean; size?: string }) =>
      props.active ? props.background : "white"};
  ${(props) => (props.active ? `background: ${props.background};` : "")}
  font-size: 16px;
  line-height: 19px;
  ${(props) =>
    props.size === "small"
      ? `
          font-size: 14px;
          line-height: 17px;
        `
      : ""};
  &:hover {
    background: ${(props) => hexToRGB(props.background, 0.4)};
    border-bottom: 1px solid ${(props) => hexToRGB(props.background, 0.4)};
  }
`;

const StyledClearIcon = styled(ClearIcon)`
  position: absolute;
  path {
    stroke: #393939 !important;
  }
  top: 50%;
  right: 40px;
  transform: translateY(-50%);
  cursor: pointer;
`;

const StyledWrapperModal = styled.div<
  {
    showOptionList: boolean;
    isHelperText?: boolean;
    openUpwards: boolean;
  } & Margin
>`
  ${(props) => [marginStyle].map((fn) => fn(props)).join("")}
  display: flex;
  opacity: ${(props) => (props.showOptionList ? "1" : "0")};
  visibility: ${(props) => (props.showOptionList ? "visible" : "hidden")};
  z-index: ${(props) => (props.showOptionList ? "-100" : "1")};
  flex-direction: column;
  position: absolute;
  padding: 16px 24px;
  width: -webkit-fill-available;
  color: #000000;
  top: ${(props) =>
    !props.openUpwards
      ? `calc(100% - ${props.isHelperText ? "19" : "0"}px)`
      : "unset"};
  bottom: ${(props) => (!props.openUpwards ? `unset` : "58px")};
  background-color: white;
  z-index: 160;
  background: #ffffff;
  box-shadow: 0px 6px 24px rgba(0, 0, 0, 0.18);
  max-height: 250px;
  overflow-y: auto;
  box-sizing: border-box;
  transition: 0.3s;
`;

const StyledSelectWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 100;
  ${(props: { disabled?: boolean }) =>
    props.disabled
      ? `
          svg {
            path {
              fill: #c8cfdc !important;
            }
          }
        `
      : ""}
`;

const StyledInput = styled.input<{
  small?: boolean;
  withClear?: boolean;
}>`
  margin: 0;
  -webkit-appearance: none;
  border: none;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  padding: 20px;
  padding-left: 15px;
  background: transparent;
  ${(props) =>
    props.small
      ? `
          font-size: 14px;
          line-height: 17px;
          padding: 15px;
          padding-left: 10px;
        `
      : ""};
  padding-top: 6.5px;
  padding-right: ${(props) => (props.withClear ? "55px" : "35px")};
  width: 100%;
  color: #19202e;
  box-sizing: border-box;
  &:focus {
    outline: none;
  }
  &:placeholder {
    color: #727272;
  }
`;

const StyledHelperText = styled.div`
  margin-top: 4px;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: ${(props: { error: boolean }) =>
    props.error ? "#D00025" : "#727272"};
`;
