import { useTranslation } from "react-i18next";
import { FormikProps } from "formik";
import { isObject, keys, map, uniq } from "lodash";
import { DateTime } from "luxon";
import { useMemo } from "react";
import ReactTooltip from "react-tooltip";
import { getColors } from "utils/getColors";
import { KIND_CHECKOUT } from "api/baseAPI/checkoutInvoiceInvoices";
import { DateTimePicker } from "components/RangeSelector/DateTimePicker";
import { Box } from "UI/Box";
import { ButtonSwitch } from "UI/ButtonSwitch";
import { Checkbox } from "UI/Form/Checkbox";
import { Select } from "UI/Form/Select";
import { TextInput } from "UI/Form/TextInput";
import { Grid } from "UI/Grid";
import { Typography } from "UI/Typography";
import { ReactComponent as InfoIcon } from "utils/img/info.svg";
import { InvoiceFormValues } from "pages/checkoutInvoice/invoices/InvoiceCreate";
import { InputTags } from "pages/invoicing/pages/parts/InputTags";
import { handleChangeNumber, numberForInput } from "utils/numbers";
import { useSelectedPointManager } from "hooks/useSelectedEntity";
import { AdditionalSettings } from "./AdditionalSettings";
import { ServiceMapping } from "./ServiceMapping";

export const FirstStep = ({
  form,
  isTemplate,
}: {
  form: FormikProps<InvoiceFormValues>;
  isTemplate?: boolean;
}) => {
  const { t } = useTranslation();
  const { accentColor } = getColors();

  const selectedPointManager = useSelectedPointManager();

  const currencyOptions = useMemo(() => {
    if (isObject(selectedPointManager.available_wallets)) {
      const currList = map(
        Object.values(selectedPointManager.available_wallets),
        (item) => item.currency
      );

      return uniq(currList).map((item) => ({
        value: item,
        label: item,
      }));
    }

    return [
      {
        value: "UAH",
        label: "UAH",
      },
    ];
  }, [selectedPointManager]);

  return (
    <>
      <Typography variant="h5">{t("Общие настройки")}</Typography>
      <Grid container pt={12} hSpace={24} vSpace={24} smHSpace={1}>
        <Grid item sm={12} md={4}>
          <TextInput
            size="small"
            label={t("Название страницы")}
            placeholder={t("Введите название страницы")}
            onChange={(value) => form.setFieldValue("title", value)}
            value={form.values.title}
            error={form.touched.title && Boolean(form.errors.title)}
            helperText={form.touched.title && form.errors.title}
          />
        </Grid>
        <Grid item sm={12} md={4}>
          <Select
            value={String(form.values.kind)}
            onChange={(value) => {
              form.setFieldValue("kind", value);
            }}
            label={t("Тип")}
            size="small"
            options={[{ value: `${KIND_CHECKOUT}`, label: t("Чекаут") }]}
            error={form.touched.kind && Boolean(form.errors.kind)}
            helperText={form.touched.kind && form.errors.kind}
          />
        </Grid>
        {!isTemplate ? (
          <Grid item sm={12} md={4}>
            <DateTimePicker
              value={
                form.values.active_till
                  ? DateTime.fromISO(form.values.active_till)
                  : null
              }
              onChange={(val) => form.setFieldValue("active_till", val)}
              placeholder={t("Срок действия")}
              label={t("Срок действия")}
              error={
                form.touched.active_till && Boolean(form.errors.active_till)
              }
              helperText={form.touched.active_till && form.errors.active_till}
            />
          </Grid>
        ) : (
          <></>
        )}
        <Grid item sm={12} md={4}>
          <Select
            value={form.values.locale}
            onChange={(value) => {
              form.setFieldValue("locale", value);
            }}
            placeholder={t("Язык")}
            label={t("Язык")}
            size="small"
            options={[
              { value: "ua", label: "UA" },
              { value: "ru", label: "RU" },
              { value: "en", label: "EN" },
              { value: "pt", label: "PT" },
              { value: "es", label: "ES" },
              { value: "fr", label: "FR" },
            ]}
            error={form.touched.locale && Boolean(form.errors.locale)}
            helperText={form.touched.locale && form.errors.locale}
          />
        </Grid>
        {!isTemplate ? (
          <Grid item sm={12} md={4}>
            <TextInput
              size="small"
              label={t("Внешний ID")}
              placeholder={t("Введите значение")}
              onChange={(value) =>
                form.setFieldValue("external_id", value.slice(0, 100))
              }
              value={form.values.external_id}
              error={
                form.touched.external_id && Boolean(form.errors.external_id)
              }
              helperText={form.touched.external_id && form.errors.external_id}
            />
          </Grid>
        ) : (
          <></>
        )}

        {!form.values.is_reusable && (
          <Grid item sm={12} md={4}>
            <TextInput
              size="small"
              label={t("ID Клиента")}
              placeholder={t("Введите значение")}
              onChange={(value) =>
                form.setFieldValue("external_customer_id", value.slice(0, 100))
              }
              value={form.values.external_customer_id}
              error={
                form.touched.external_customer_id &&
                Boolean(form.errors.external_customer_id)
              }
              helperText={
                form.touched.external_customer_id &&
                form.errors.external_customer_id
              }
            />
          </Grid>
        )}
      </Grid>

      <Grid
        container
        pt={12}
        hSpace={24}
        vSpace={24}
        smHSpace={1}
        alignItems="center"
      >
        <ServiceMapping form={form} />

        {keys(form.values.service_mapping)?.length > 1 && (
          <Grid container style={{gap: "0 12px"}} sm={6} alignItems="center">
            <Grid item>
              <InfoIcon data-tip data-for="info" />
              <ReactTooltip
                id="info"
                border
                place="top"
                multiline
                type="dark"
                effect="solid"
                borderColor={accentColor}
              >
                <span>
                  {t(
                    "При наличии настройки инвойсинг будет автоматически переводить на оплату по данному методу при первом открытии"
                  )}
                </span>
              </ReactTooltip>
            </Grid>

            <Grid item>
              <Select
                value={form.values.auto_checkout}
                onChange={(value) => {
                  form.setFieldValue("auto_checkout", value);
                }}
                label={t("Платежный метод по умолчанию")}
                placeholder={t("Выберите")}
                size="small"
                options={[
                  { value: "", label: t("Не выбран") },
                  ...map(keys(form.values.service_mapping), (slug) => ({
                    value: slug,
                    label: slug,
                  })),
                ]}
                error={
                  form.touched.auto_checkout &&
                  Boolean(form.errors.auto_checkout)
                }
                helperText={
                  form.touched.auto_checkout && form.errors.auto_checkout
                }
              />
            </Grid>
          </Grid>
        )}
        {keys(form.values.service_mapping)?.length > 0 && (
          <Grid sm={6} item alignItems="center">
            <Checkbox
              value={form.values.show_commission}
              label={t("Показывать комиссию")}
              onChange={() => {
                form.setFieldValue(
                  "show_commission",
                  !form.values.show_commission
                );
              }}
            />
          </Grid>
        )}
      </Grid>

      <Typography variant="h5" mt={12}>
        {t("Настройки оплаты")}
      </Typography>
      <Grid container pt={12} hSpace={24} vSpace={24} smHSpace={1}>
        <Grid item sm={12} md={4}>
          <TextInput
            size="small"
            label={t("Сумма")}
            placeholder={t("Введите сумму")}
            onChange={(value) => {
              form.setFieldValue(
                "fixed_amount",
                handleChangeNumber(value.replace(/[^0-9.]/g, ""))
              );
              form.setFieldValue("preferred_amounts", []);
            }}
            value={numberForInput(form.values.fixed_amount)}
            error={
              form.touched.fixed_amount && Boolean(form.errors.fixed_amount)
            }
            helperText={form.touched.fixed_amount && form.errors.fixed_amount}
          />
        </Grid>
        <Grid item sm={12} md={4}>
          <Select
            value={form.values.amount_currency}
            onChange={(value) => form.setFieldValue("amount_currency", value)}
            label={t("Валюта")}
            size="small"
            options={currencyOptions}
            error={
              form.touched.amount_currency &&
              Boolean(form.errors.amount_currency)
            }
            helperText={
              form.touched.amount_currency && form.errors.amount_currency
            }
          />
        </Grid>
        {!form.values.fixed_amount && (
          <Grid item sm={12} md={4}>
            <TextInput
              size="small"
              label={t("Минимальная сумма")}
              placeholder={t("Введите сумму")}
              onChange={(value) => {
                form.setFieldValue(
                  "min_amount",
                  handleChangeNumber(value.replace(/[^0-9.]/g, ""))
                );
                form.setFieldValue("preferred_amounts", []);
              }}
              value={numberForInput(form.values.min_amount)}
              error={form.touched.min_amount && Boolean(form.errors.min_amount)}
              helperText={form.touched.min_amount && form.errors.min_amount}
            />
          </Grid>
        )}
        {!form.values.fixed_amount && (
          <Grid item sm={12} md={4}>
            <TextInput
              size="small"
              label={t("Дефолтная сумма")}
              placeholder={t("Введите сумму")}
              onChange={(value) => {
                form.setFieldValue(
                  "default_amount",
                  handleChangeNumber(value.replace(/[^0-9.]/g, ""))
                );
                form.setFieldValue("preferred_amounts", []);
              }}
              value={numberForInput(form.values.default_amount)}
              error={
                form.touched.default_amount &&
                Boolean(form.errors.default_amount)
              }
              helperText={
                form.touched.default_amount && form.errors.default_amount
              }
            />
          </Grid>
        )}
        {!form.values.fixed_amount && (
          <Grid item sm={12} md={4}>
            <InputTags
              name="preferred_amounts"
              size="small"
              value={[...form.values.preferred_amounts]?.sort(
                (a: string, b: string) => Number(a) - Number(b)
              )}
              onChange={(newValue: string[]) => {
                form.setFieldValue("preferred_amounts", newValue);
              }}
              label={t("Предлагаемые суммы")}
              placeholder={t("Введите сумму")}
              error={
                form.touched.preferred_amounts &&
                Boolean(form.errors.preferred_amounts)
              }
              {...(form.errors.preferred_amounts &&
              form.touched.preferred_amounts
                ? {
                    helperText: String(form.errors.preferred_amounts),
                  }
                : {})}
              {...(((form.errors.preferred_amounts &&
                !form.touched.preferred_amounts) ||
                !form.errors.preferred_amounts) &&
              form.values.preferred_amounts?.length > 0
                ? {
                    helperText: t("Поле принимает от 2 до 3 вариантов"),
                  }
                : {})}
            />
          </Grid>
        )}
        {!form.values.fixed_amount && !!form.values.preferred_amounts?.length && (
          <Grid item sm={12} md={6} lg={4}>
            <Box flex alignItems="center" nowrap>
              <ButtonSwitch
                firstTitle={t("Добавлять")}
                secondTitle={t("Заменять")}
                value={form.values.preferred_amounts_logic === 1}
                onChange={() =>
                  form.setFieldValue(
                    "preferred_amounts_logic",
                    form.values.preferred_amounts_logic === 2 ? 1 : 2
                  )
                }
              />
            </Box>
          </Grid>
        )}
        <Grid item sm={12} md={4} flex alignItems="center">
          <Checkbox
            value={form.values.is_reusable}
            onChange={() =>
              form.setFieldValue("is_reusable", !form.values.is_reusable)
            }
            label={t("Повторные оплаты")}
          />
        </Grid>
      </Grid>

      {form.values.kind === KIND_CHECKOUT && <AdditionalSettings form={form} />}
    </>
  );
};
