import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { FormikProps, useFormik } from "formik";
import { find, isEmpty, keys, map, omit, toPairs } from "lodash";
import styled from "styled-components";
import * as Yup from "yup";
import {
  useGetPointServiceAvailableMethodsQuery,
  useGetPointServicesQuery,
  usePaymentMethodNames,
} from "api/baseAPI/pointServices";
import { StyledCell, StyledRow, Table } from "components/table/Table";
import { Button } from "UI/Button";
import { Select } from "UI/Form/Select";
import { Grid } from "UI/Grid";
import { Typography } from "UI/Typography";
import { InvoiceFormValues } from "pages/checkoutInvoice/invoices/InvoiceCreate";
import { ReactComponent as DeleteIcon } from "utils/img/delete-midle.svg";
import { ReactComponent as EditIcon } from "utils/img/edit.svg";
import { useSelectedPointManager } from "hooks/useSelectedEntity";

export const ServiceMapping = ({
  form,
}: {
  form: FormikProps<InvoiceFormValues>;
}) => {
  const { t } = useTranslation();
  const [addSetting, setAddSetting] = useState<boolean>(false);
  const [editSetting, setEditSetting] = useState<string | null>(null);
  const [searchService, setSearchService] = useState("");

  const selectedPointManager = useSelectedPointManager();

  const pointServices = useGetPointServicesQuery({
    page: "1",
    page_size: "300",
    search: "",
    point: selectedPointManager.point?.id,
  });

  const pointServicesOptions = useGetPointServicesQuery({
    page: "1",
    page_size: "300",
    ...(!isNaN(Number(searchService))
      ? {
          service: searchService,
          search: "",
        }
      : {
          search: searchService,
        }),
    point: selectedPointManager.point?.id,
  });

  const paymentMethods = useGetPointServiceAvailableMethodsQuery({
    page: "1",
    page_size: "100",
    search: "",
    point: `${selectedPointManager?.point_id || ""}`,
  });

  const paymentMethodNames = usePaymentMethodNames();

  const getServices = useMemo(() => {
    const { data } = pointServices;
    if (data && data?.results?.length) {
      const services = Array.from(
        new Set(
          data?.results?.map((item) => ({
            value: String(item.service?.id),
            label: `${item.service?.id} | ${item.service?.name}`,
            slug: item.service?.method?.slug || "",
          }))
        )
      );
      return services;
    }
    return [];
  }, [pointServices?.data]);

  const getServicesOptions = useMemo(() => {
    const { data } = pointServicesOptions;
    if (data && data?.results?.length) {
      const services = Array.from(
        new Set(
          data?.results?.map((item) => ({
            value: String(item.service?.id),
            label: `${item.service?.id} | ${item.service?.name}`,
            slug: item.service?.method?.slug || "",
          }))
        )
      );
      return services;
    }
    return [];
  }, [pointServicesOptions?.data]);

  const getPaymentMethods = useMemo(() => {
    const { data } = paymentMethods;
    if (data && data?.length) {
      const elements = Array.from(
        new Set(
          data?.map((slug) => ({
            value: slug,
            label: paymentMethodNames?.[slug] || slug,
          }))
        )
      );
      return elements;
    }
    return [];
  }, [paymentMethods]);

  const getWallets = useMemo(
    () =>
      selectedPointManager?.available_wallets?.length > 0
        ? selectedPointManager?.available_wallets?.map((item) => ({
            value: `${item.id}`,
            label: `${item.id} | ${item.name}`,
          }))
        : [],
    [selectedPointManager]
  );

  const formConfig = useFormik<{
    method: string;
    service: string;
    wallet: string;
  }>({
    initialValues: {
      method: "",
      service: "",
      wallet: "",
    },
    onSubmit: (values) => {
      form.setFieldValue("service_mapping", {
        ...form.values.service_mapping,
        ...(values.method && values.service
          ? {
              [values.method]: Number(values.service),
            }
          : {}),
      });
      form.setFieldValue("wallet_mapping", {
        ...form.values.wallet_mapping,
        ...(values.method && values.wallet
          ? {
              [values.method]: Number(values.wallet),
            }
          : {}),
      });
      setTimeout(() => {
        form.validateField("service_mapping");
      });
      formConfig.resetForm();
      setAddSetting(false);
      setEditSetting(null);
    },
    validationSchema: Yup.object().shape({
      method: Yup.string()
        .test(
          "uniq method",
          t("Данный метод уже был выбран"),
          (value) =>
            !(
              value &&
              keys(form.values.service_mapping).includes(value) &&
              !editSetting
            )
        )
        .required(t("Обязательное поле")),
      service: Yup.string().required(t("Обязательное поле")),
      wallet: Yup.string().required(t("Обязательное поле")),
    }),
  });

  useEffect(() => {
    if (formConfig.values.service && !editSetting) {
      const serviceData = find(getServices, {
        value: String(formConfig.values.service),
      });

      if (serviceData) {
        formConfig.setFieldTouched("method", true, true);
        formConfig.setFieldValue("method", serviceData.slug);
      }
    }
  }, [formConfig.values.service]);

  const handleRemoveSlug = (slug: string) => {
    const newServiceMapping = omit(form.values.service_mapping, [slug]);
    const newWalletMapping = omit(form.values.wallet_mapping, [slug]);
    form.setFieldValue("service_mapping", newServiceMapping);
    form.setFieldValue("wallet_mapping", newWalletMapping);

    if (slug === editSetting) {
      formConfig.resetForm();
      setEditSetting(null);
    }

    if (
      slug === form.values.auto_checkout ||
      keys(newServiceMapping).length < 2
    ) {
      form.setFieldValue("auto_checkout", "");
    }
  };

  const handleEditSetting = (slug: string) => {
    formConfig.resetForm();
    const serviceMappingField = form.values.service_mapping[slug];
    const walletMappingField = form.values.wallet_mapping[slug];

    if (serviceMappingField) {
      formConfig.setFieldValue("service", String(serviceMappingField));
      formConfig.setFieldValue("method", slug);
      setEditSetting(slug);
    }

    if (walletMappingField) {
      formConfig.setFieldValue("wallet", String(walletMappingField));
    }
  };

  return (
    <Grid item sm={12}>
      <Typography variant="h5">{t("Услуги")}</Typography>
      <Grid container mt={8} hSpace={24} vSpace={24} smHSpace={1}>
        <Grid item sm={12} lg={12}>
          <Table
            isLoading={false}
            tableCellTitles={[t("Услуга"), t("Платежный метод"), t("Кошелек")]}
            count={keys(form.values.service_mapping)?.length || 0}
            page={1}
            rowsPerPage={1}
            disabledPagination
            setPage={() => {}}
            setRowsPerPage={() => {}}
          >
            {!isEmpty(form.values.service_mapping) ? (
              map(toPairs(form.values.service_mapping), ([slug, service]) => (
                <StyledRow key={slug}>
                  <StyledCell style={{ padding: "11px 8px" }}>
                    {`${(
                      find(getServices, { value: String(service) })?.label ||
                      String(service)
                    )?.substring(0, 40)}${
                      (find(getServices, { value: String(service) })?.label
                        ?.length || 0) > 40
                        ? "..."
                        : ""
                    }`}
                  </StyledCell>
                  <StyledCell>{paymentMethodNames?.[slug] || slug}</StyledCell>
                  <StyledCell
                    style={{ padding: "11px 8px" }}
                    isError={!form.values.wallet_mapping?.[slug]}
                  >
                    {form.values.wallet_mapping?.[slug]
                      ? `${(
                          find(getWallets, {
                            value: String(form.values.wallet_mapping?.[slug]),
                          })?.label ||
                          String(form.values.wallet_mapping?.[slug])
                        )?.substring(0, 40)}${
                          (find(getWallets, {
                            value: String(form.values.wallet_mapping?.[slug]),
                          })?.label?.length || 0) > 40
                            ? "..."
                            : ""
                        }`
                      : t("Обязательное поле")}
                  </StyledCell>
                  <StyledCell style={{ padding: "11px 8px" }}>
                    <Grid container hSpace={12}>
                      <StyledIconWrapper>
                        <EditIcon onClick={() => handleEditSetting(slug)} />
                      </StyledIconWrapper>
                      <StyledIconWrapper>
                        <DeleteIcon onClick={() => handleRemoveSlug(slug)} />
                      </StyledIconWrapper>
                    </Grid>
                  </StyledCell>
                </StyledRow>
              ))
            ) : (
              <></>
            )}
          </Table>

          {form.touched.service_mapping &&
          Boolean(form.errors.service_mapping) ? (
            <StyledHelperText iserror>
              {String(form.errors.service_mapping)}
            </StyledHelperText>
          ) : null}
        </Grid>
        <Grid item sm={12} lg={12}>
          {addSetting || !!editSetting ? (
            <Grid container hSpace={12} vSpace={12} smHSpace={1}>
              <Grid item sm={4}>
                <Select
                  value={formConfig.values.service}
                  onChange={(value) => {
                    formConfig.setFieldValue("service", value);
                  }}
                  inputValue={searchService}
                  onChangeInputValue={(val) => setSearchService(val)}
                  isLoading={pointServicesOptions.isFetching}
                  label={t("Услуга")}
                  placeholder={t("Выберите услугу")}
                  size="small"
                  options={getServicesOptions}
                  disabled={!!editSetting}
                  error={
                    formConfig.touched.service &&
                    Boolean(formConfig.errors.service)
                  }
                  helperText={
                    formConfig.touched.service && formConfig.errors.service
                  }
                />
              </Grid>
              <Grid item sm={12} lg={4}>
                <Select
                  value={formConfig.values.method}
                  onChange={(value) => {
                    formConfig.setFieldValue("method", value);
                  }}
                  label={t("Платежный метод")}
                  placeholder={t("Выберите")}
                  size="small"
                  options={getPaymentMethods}
                  disabled={!!editSetting}
                  error={
                    formConfig.touched.method &&
                    Boolean(formConfig.errors.method)
                  }
                  helperText={
                    formConfig.touched.method && formConfig.errors.method
                  }
                />
              </Grid>
              <Grid item sm={4}>
                <Select
                  value={formConfig.values.wallet}
                  onChange={(value) => {
                    formConfig.setFieldValue("wallet", value);
                  }}
                  label={t("Кошелек")}
                  placeholder={t("Выберите кошелек")}
                  size="small"
                  options={getWallets}
                  error={
                    formConfig.touched.wallet &&
                    Boolean(formConfig.errors.wallet)
                  }
                  helperText={
                    formConfig.touched.wallet && formConfig.errors.wallet
                  }
                />
              </Grid>
              <Grid item sm={6}>
                <Button
                  variant="outlined"
                  onClick={() => {
                    formConfig.resetForm();
                    setAddSetting(false);
                    setEditSetting(null);
                  }}
                  fullwidth
                >
                  {t("Отмена")}
                </Button>
              </Grid>
              <Grid item sm={6}>
                <Button
                  fullwidth
                  variant="contained"
                  onClick={() => formConfig.handleSubmit()}
                >
                  {t("Сохранить")}
                </Button>
              </Grid>
            </Grid>
          ) : (
            <Grid container hSpace={24} vSpace={24} smHSpace={1}>
              <Grid item sm={6}>
                <Button
                  fullwidth
                  variant="outlined"
                  onClick={() => setAddSetting(true)}
                  disabled={addSetting}
                >
                  {t("Добавить услугу")}
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

const StyledIconWrapper = styled.div`
  cursor: pointer;
`;

const StyledHelperText = styled.div`
  font-weight: 400;
  margin-top: 4px;
  font-size: 12px;
  line-height: 15px;
  color: ${(props: { iserror: boolean }) =>
    props.iserror ? "#D00025" : "#727272"};
`;
