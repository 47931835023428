import { find, isBoolean, isEmpty, keys } from "lodash";
import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useGetUsersSelfQuery } from "api/baseAPI/user";
import { useSelectedPointManager } from "hooks/useSelectedEntity";
import { useStyle } from "hooks/useStyle";
import { getColors } from "utils/getColors";
import getSettingStyles from "utils/getSettingStyles";
import { ReactComponent as Close } from "utils/img/close.svg";
import { ReactComponent as MenuBurger } from "utils/img/menu-burger.svg";
import { RESPONSIVE_SIZES } from "utils/tools";
import { useMenuState } from "utils/useMenuState";
import { ErrorBoundary } from "components/ErrorBoundary";
import { Helpcrunch } from "components/Helpcrunch";
import { HeaderActions } from "./HeaderActions";
import { Menu, menuLink } from "./Menu";
import { PointManager } from "./PointManager";

export const Layout = ({
  title,
  children,
  breadCrumbs,
}: {
  title: string;
  children: React.ReactChild | React.ReactChild[];
  breadCrumbs?: string[][];
}): React.ReactElement => {
  const navigate = useNavigate();
  getSettingStyles();
  const style = useStyle();

  const [isMenuCollapsed, setIsMenuCollapsed] = useMenuState();

  const { sidebarBackground, pageBackground } = getColors();

  const selectedPointManager = useSelectedPointManager();

  useEffect(() => {
    const pathnameSplit = window.location.pathname.split("/");
    const findComponentName = find(
      keys(menuLink),
      (key) =>
        menuLink[key] ===
        `/${pathnameSplit?.[1]}${
          pathnameSplit?.[2] ? `/${pathnameSplit?.[2]}` : ""
        }`
    );
    const findComponent = find(
      selectedPointManager?.available_components,
      (el) => el.name === findComponentName
    );
    if (!findComponent?.id && window.location.pathname !== "/profile") {
      // const findFirsAvailableComponents = find(
      //   selectedPointManager?.available_components,
      //   (el) => !!menuLink[el.name]
      // );
      // navigate(menuLink?.[findFirsAvailableComponents?.name || "report"]);
    }
  }, [window.location, selectedPointManager]);

  const getUsersSelfQuery = useGetUsersSelfQuery();

  useEffect(() => {
    document.title = title;
  }, [title]);

  const toggleMenu = () => {
    setIsMenuCollapsed(!isMenuCollapsed);
  };

  useEffect(() => {
    if (
      getUsersSelfQuery.isSuccess &&
      isBoolean(getUsersSelfQuery.data.is_approved) &&
      !getUsersSelfQuery.data.is_approved
    ) {
      navigate("/onboarding");
    }
  }, [getUsersSelfQuery]);

  return (
    <StyledPageWrapper>
      <StyledSidebar
        isMenuCollapsed={isMenuCollapsed}
        onClick={(e) => {
          if (isMenuCollapsed) {
            toggleMenu();
            e.preventDefault();
          }
        }}
        background={sidebarBackground}
      >
        <StyledWrapperLogo>
          {!isMenuCollapsed && (
            <Link to="/">
              <StyledLogo src={style.logo} />
            </Link>
          )}
          <StyledMenuBurger onClick={toggleMenu} />
          <StyledClose onClick={toggleMenu} />
        </StyledWrapperLogo>

        <PointManager
          isMenuCollapsed={isMenuCollapsed}
          userId={getUsersSelfQuery.data?.id || 0}
        />
        <HeaderActions
          avatar={getUsersSelfQuery.data?.avatar}
          username={`${getUsersSelfQuery.data?.first_name || ""} ${
            getUsersSelfQuery.data?.last_name || ""
          }`}
          mobile
          toggleMenu={() =>
            window.innerWidth < 768 && setIsMenuCollapsed(!isMenuCollapsed)
          }
        />
        <Menu
          isMenuCollapsed={isMenuCollapsed}
          toggleMenu={() =>
            window.innerWidth < 768 && setIsMenuCollapsed(!isMenuCollapsed)
          }
        />
      </StyledSidebar>
      <StyledFooterMobile
        background={sidebarBackground}
        isMenuCollapsed={isMenuCollapsed}
      >
        <StyledItemFooter>
          <MenuBurger onClick={toggleMenu} />
        </StyledItemFooter>
        <StyledItemFooter>{/* <StyledBell /> */}</StyledItemFooter>
        <PointManager
          isMenuCollapsed
          isMenuFooter
          userId={getUsersSelfQuery.data?.id || 0}
        />
      </StyledFooterMobile>
      <StyledMain isMenuCollapsed={isMenuCollapsed} background={pageBackground}>
        <StyledHeader>
          <StyledHeaderTitle>
            {!isEmpty(breadCrumbs) && breadCrumbs ? (
              <>
                {breadCrumbs.map((item, index) =>
                  (breadCrumbs?.length || 0) - 1 === index ? (
                    <StyledBlackBreadCrumbs key={index}>
                      &nbsp;{` / ${item[0]}`}
                    </StyledBlackBreadCrumbs>
                  ) : item[1] ? (
                    <StyledLinkBreadCrumbs
                      key={index}
                      color="inherit"
                      to={item[1]}
                    >
                      &nbsp;{` / ${item[0]}`}
                    </StyledLinkBreadCrumbs>
                  ) : (
                    `${item[0]} `
                  )
                )}
              </>
            ) : (
              title
            )}
          </StyledHeaderTitle>
          <StyledPageActionWrapper>
            <HeaderActions
              avatar={getUsersSelfQuery.data?.avatar}
              username={`${getUsersSelfQuery.data?.first_name || ""} ${
                getUsersSelfQuery.data?.last_name || ""
              }`}
            />
          </StyledPageActionWrapper>
        </StyledHeader>
        <StyledHeaderTitleMobile>
          {!isEmpty(breadCrumbs) && breadCrumbs ? (
            <>
              {breadCrumbs.map((item, index) =>
                (breadCrumbs?.length || 0) - 1 === index ? (
                  <StyledBlackBreadCrumbs key={index}>
                    &nbsp;{` / ${item[0]}`}
                  </StyledBlackBreadCrumbs>
                ) : item[1] ? (
                  <StyledLinkBreadCrumbs
                    key={index}
                    color="inherit"
                    to={item[1]}
                  >
                    &nbsp;{` / ${item[0]}`}
                  </StyledLinkBreadCrumbs>
                ) : (
                  `${item[0]} `
                )
              )}
            </>
          ) : (
            title
          )}
        </StyledHeaderTitleMobile>
        <ErrorBoundary>
          <>{children}</>
        </ErrorBoundary>
      </StyledMain>
      {style?.helpcrunch_organization && style?.helpcrunch_app_id ? (
        <Helpcrunch
          organization={style.helpcrunch_organization}
          appId={style?.helpcrunch_app_id}
        />
      ) : (
        <></>
      )}
    </StyledPageWrapper>
  );
};

// Style
const StyledMenuBurger = styled(MenuBurger)`
  cursor: pointer;
  @media (${RESPONSIVE_SIZES.sm}) {
    display: none;
  }
`;
const StyledClose = styled(Close)`
  display: none;
  @media (${RESPONSIVE_SIZES.sm}) {
    display: block;
  }
`;
// const StyledBell = styled(Bell)`
//   path {
//     fill: #ffffff !important;
//   }
// `;

const StyledItemFooter = styled.div`
  width: 33%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledPageWrapper = styled.div`
  width: 100%;
  display: flex;
  min-height: 100vh;
  @media (${RESPONSIVE_SIZES.sm}) {
    overflow-x: hidden;
    max-width: 100%;
  }
`;

const StyledFooterMobile = styled.div<{
  isMenuCollapsed: boolean;
  background: string;
}>`
  width: 100%;
  position: fixed;
  z-index: 105;
  transition: 0.2s;
  box-sizing: border-box;
  background: ${(props) => props.background};
  position: fixed;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  display: ${(props) => (props.isMenuCollapsed ? "flex" : "none")};
  height: ${(props) => (props.isMenuCollapsed ? "64px" : "0")};
  @media (${RESPONSIVE_SIZES.sm}) {
    position: fixed;
  }
  @media (${RESPONSIVE_SIZES.overSm}) {
    display: none;
  }
`;

const StyledSidebar = styled.div<{
  isMenuCollapsed: boolean;
  background: string;
}>`
  overflow-y: auto;
  height: 100%;
  width: ${(props) => (props.isMenuCollapsed ? "92px" : "256px")};
  position: fixed;
  z-index: 115;
  transition: 0.2s;
  box-sizing: border-box;
  background: ${(props) => props.background};
  min-height: 100vh;
  @media (${RESPONSIVE_SIZES.sm}) {
    position: fixed;
    overflow: auto;
    width: 100%;
    height: 100vh;
    display: ${(props) => (!props.isMenuCollapsed ? "block" : "none")};
  }
  @media (${RESPONSIVE_SIZES.overSm}) {
    display: block;
  }
`;

const StyledLogo = styled.img`
  max-width: 125px;
  max-height: 65px;
  width: 100%;
`;

const StyledMain = styled.div<{
  isMenuCollapsed: boolean;
  background: string;
}>`
  width: ${(props) =>
    props.isMenuCollapsed ? "calc(100% - 92px)" : "calc(100% - 256px)"};
  margin-left: ${(props) => (props.isMenuCollapsed ? "92px" : "256px")};
  box-sizing: border-box;
  background: ${(props) => props.background};
  @media (${RESPONSIVE_SIZES.sm}) {
    width: 100%;
    margin-left: 0;
    margin-bottom: 64px;
  }
`;

const StyledHeader = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 61px;
  padding-left: 24px;
  background: #ffffff;
  border-bottom: 1px solid #e9edf3;
  z-index: 1000;
  position: relative;
  @media (${RESPONSIVE_SIZES.sm}) {
    display: none;
  }
`;

const StyledWrapperLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 18px;
  height: 62px;
`;

const StyledBlackBreadCrumbs = styled.div`
  color: #000000;
  text-decoration: none;
`;

const StyledHeaderTitle = styled.div`
  display: flex;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  color: #838383;
  @media (${RESPONSIVE_SIZES.xl}) {
    font-size: 18px;
    line-height: 18px;
  }
  @media (${RESPONSIVE_SIZES.lg}) {
    font-size: 15px;
    line-height: 15px;
  }
  @media (${RESPONSIVE_SIZES.md}) {
    font-size: 14px;
    line-height: 14px;
  }
`;

const StyledHeaderTitleMobile = styled.div`
  display: flex;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  padding: 24px;
  color: #838383;
  background: #ffffff;
  border-bottom: 1px solid #e9edf3;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  &::-webkit-scrollbar {
    height: 0;
  }
  @media (max-width: 510px) {
    font-size: 16px;
    line-height: 16px;
  }
  @media (max-width: 460px) {
    font-size: 13px;
    line-height: 13px;
  }
  @media (${RESPONSIVE_SIZES.overSm}) {
    display: none;
  }
`;

const StyledLinkBreadCrumbs = styled(Link)`
  color: #000000;
  text-decoration: none;
`;

const StyledPageActionWrapper = styled.div`
  display: flex;
  align-items: center;
  @media (${RESPONSIVE_SIZES.sm}) {
    display: none !important;
  }
`;
