import i18n from "i18next";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Box } from "UI/Box";
import { Divider } from "UI/Divider";
import { Typography } from "UI/Typography";
import { useLogoutMutation } from "api/baseAPI/user";
import { useAuth } from "hooks/useAuth";
import { removeAuth } from "reducers/authSlice";
import { getColors } from "utils/getColors";
import { ReactComponent as ArrowUpDown } from "utils/img/arrow-up-down.svg";
import { ReactComponent as LogoutIcon } from "utils/img/logout.svg";
import { ReactComponent as PeopleIcon } from "utils/img/people.svg";
import { RESPONSIVE_SIZES } from "utils/tools";

const languageList = process.env.REACT_APP_INTERFACE_LANGS
  ? process.env.REACT_APP_INTERFACE_LANGS.split(",")
  : ["ru", "en", "ua", "es", "pt"];

export const HeaderActions = ({
  avatar,
  username,
  mobile,
  toggleMenu,
}: {
  avatar?: string;
  username?: string;
  mobile?: boolean;
  toggleMenu?: () => void;
}): React.ReactElement => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isOpenLanguageMenu, setIsOpenLanguageMenu] = useState(false);
  const [isOpenUserInfo, setIsOpenUserInfo] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const languageMenuRef = useRef<HTMLDivElement>(null);
  const userInfoMenuRef = useRef<HTMLDivElement>(null);

  const auth = useAuth();
  const [logout] = useLogoutMutation();

  const { sidebarBackground } = getColors();

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        languageMenuRef.current &&
        !languageMenuRef.current.contains(event.target as Node)
      ) {
        setIsOpenLanguageMenu(false);
      }
    }

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [languageMenuRef]);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        userInfoMenuRef.current &&
        !userInfoMenuRef.current.contains(event.target as Node)
      ) {
        setIsOpenUserInfo(false);
      }
    }

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [userInfoMenuRef]);

  const handleChangeLocalization = (lang: string) => {
    setIsOpenLanguageMenu(false);
    i18n.changeLanguage(lang);
    localStorage.setItem("lang", lang);
  };

  const handleLogout = () => {
    const token = auth.access_token;
    dispatch(removeAuth());
    window.localStorage.removeItem("point_manager");
    window.localStorage.removeItem("selected_wallet");
    logout({ token }).unwrap();
    navigate("/login");
  };

  return (
    <StyleWrapper mobile={!!mobile} ref={ref}>
      <StyledItem background={sidebarBackground}>
        <StyledUserMenuWrapper
          ref={userInfoMenuRef}
          onClick={() => setIsOpenUserInfo(!isOpenUserInfo)}
        >
          <StyledFlex>
            <StyledUserLogo src={avatar} />
            <StyledUserInfo>{username}</StyledUserInfo>
          </StyledFlex>
          <StyledArrowUpDown open={isOpenUserInfo} />
          {isOpenUserInfo && (
            <StyledUserInfoMenu>
              <Box
                pb={16}
                flex
                onClick={() => {
                  navigate({
                    pathname: "/profile",
                    search: "?refrech=start",
                  });
                  if (mobile && toggleMenu) toggleMenu();
                }}
                style={{ cursor: "pointer" }}
              >
                <PeopleIcon />
                <Typography variant="subtitle2" ml={14}>
                  {t("Мой профиль")}
                </Typography>
              </Box>
              <Divider direction="vertical" />
              <Box
                pt={16}
                flex
                nowrap
                onClick={handleLogout}
                style={{ cursor: "pointer" }}
              >
                <LogoutIcon />
                <Typography variant="subtitle2" ml={14}>
                  {t("Выйти из аккаунта")}
                </Typography>
              </Box>
            </StyledUserInfoMenu>
          )}
        </StyledUserMenuWrapper>
      </StyledItem>
      <StyledItem background={sidebarBackground} open={isOpenLanguageMenu}>
        <StyledLanguageWrapper
          ref={languageMenuRef}
          onClick={() => setIsOpenLanguageMenu(!isOpenLanguageMenu)}
        >
          <div>{i18n.language === "uk" ? "ua" : i18n.language}</div>
          <StyledArrowUpDownWhite open={isOpenLanguageMenu} />
          {isOpenLanguageMenu && (
            <StyledLanguageMenu>
              {languageList.map((lang) => (
                <StyledLanguageMenuItem
                  key={lang}
                  onClick={() =>
                    handleChangeLocalization(lang === "ua" ? "uk" : lang)
                  }
                  selected={(lang === "ua" ? "uk" : lang) === i18n.language}
                >
                  {lang}
                </StyledLanguageMenuItem>
              ))}
            </StyledLanguageMenu>
          )}
        </StyledLanguageWrapper>
      </StyledItem>
    </StyleWrapper>
  );
};

const StyledItem = styled.div`
  padding: 0 28px;
  height: 60px;
  border-left: 1px solid #e9edf3;
  display: flex;
  align-items: center;
  position: relative;
  ${(props: { open?: boolean; background: string }) =>
    props.open
      ? `
          background: ${props.background};
          color: #ffffff;
        `
      : ""};
  @media (${RESPONSIVE_SIZES.sm}) {
    border-left: none;
    padding: 0 36px;
    width: calc(100% - 72px);
  }
`;

const StyledFlex = styled.div`
  display: flex;
  align-items: center;
`;

const StyledUserMenuWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-width: 150px;
`;

const StyledUserInfo = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #000000;
  padding-left: 12px;
  @media (${RESPONSIVE_SIZES.sm}) {
    color: #ffffff;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
  }
`;

const StyledUserLogo = styled.img`
  width: 40px;
  height: 40px;
`;
const StyledLanguageWrapper = styled.div`
  position: relative;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  cursor: pointer;
  @media (${RESPONSIVE_SIZES.sm}) {
    color: #ffffff;
    justify-content: space-between;
    width: 100%;
  }
`;
const StyledLanguageMenu = styled.div`
  position: absolute;
  padding: 6px 0;
  color: #000000;
  left: -28px;
  top: 40px;
  background-color: white;
  box-shadow: 0px 6px 24px rgba(0, 0, 0, 0.18);
  border: 1px solid #ebebeb;
  z-index: 1000;
  @media (${RESPONSIVE_SIZES.sm}) {
    width: 100%;
    padding: 6px 33px;
    left: -33px;
  }
`;
const StyledUserInfoMenu = styled.div`
  position: absolute;
  padding: 16px;
  color: #000000;
  left: -28px;
  top: 50px;
  width: calc(100% + 24px);
  background-color: white;
  box-shadow: 0px 6px 24px rgba(0, 0, 0, 0.18);
  border: 1px solid #ebebeb;
  z-index: 100;
  @media (${RESPONSIVE_SIZES.sm}) {
    width: 100%;
    padding: 16px 33px;
    left: -33px;
  }
`;
const StyledLanguageMenuItem = styled.div`
  width: 20px;
  padding: 6px 24px;
  font-size: 14px;
  line-height: 17px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  opacity: ${(props: { selected: boolean }) => (props.selected ? "0.5" : "1")};
  @media (${RESPONSIVE_SIZES.sm}) {
    width: 100%;
    padding: 6px 0;
  }
`;
const StyledArrowUpDown = styled(ArrowUpDown)`
  padding-left: 16px;
  ${(props: { open: boolean }) =>
    props.open
      ? `
          padding-right: 16px;
          padding-left: 0;
          transform: rotate(180deg);
          
          path {
            fill: #000000 !important;
          }
        `
      : ""};
  @media (${RESPONSIVE_SIZES.sm}) {
    path {
      fill: #ffffff !important;
    }
  }
`;
const StyledArrowUpDownWhite = styled(StyledArrowUpDown)`
  ${(props: { open: boolean }) =>
    props.open
      ? `
          path {
            fill: #ffffff !important;
          }
        `
      : ""};
`;
// const StyledBell = styled(Bell)`
//   cursor: pointer;
// `;

const StyleWrapper = styled.div`
  display: ${(props: { mobile: boolean }) => (props.mobile ? "none" : "flex")};
  @media (${RESPONSIVE_SIZES.sm}) {
    display: block;
  }
`;
